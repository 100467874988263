// 系统共通变量配置文件

// storage缓存key
export const STORAGEKEY_USERINFO = "userInfo";
export const STORAGEKEY_LANGUAGE = "language";
export const STORAGEKEY_TOKEN = "overseasToken";
export const STORAGEKEY_GOOGLECODE = "GOOGLECODE";

// 共通变量
export const STAFFCD_MAXLEN = 50; // staffCD最大长度
export const PASSWORD_MAXLEN = 20; // password最大长度
export const PASSWORD_MINLEN = 8; // password最大长度
export const EMAIL_MAXLEN = 50; // email最大长度
export const MAILVERIFICATIONCODE_LEN = 6; // email验证码最大长度

// 默认语言
export const DEFAULT_LANGUAGE = "ja_JP";
export const LOGINOUT_TIME = 1000 * 60 * 60;

// 分页size
export const DEFAULT_PAGESIZE_OPTIONS = [10, 30, 50];

export const SEARCH_FORM_LAYOUT = {
  labelCol: { span: 11 },
  wrapperCol: { span: 13 },
};
export const SEARCH_LAYOUT_ITEM = {
  xs: 24,
  sm: 16,
  md: 8,
  lg: 8,
  xl: 5,
};
export const SEARCH_LAYOUT_BTN = {
  xs: 16,
  sm: 4,
  md: 4,
  lg: 4,
  xl: 4,
};

export const FANTASY_ABROAD = "FANTASY_ABROAD"; // 海外
export const FANTASY_ABROAD_SALE_STORE = "FANTASY_ABROAD_SALE_STORE"; // 海外
export const FANTASY_JP_LOGIN = "FANTASY_JP_LOGIN"; // 本部
export const FANTASY_JP = "FANTASY_JP"; // 阅览
export const FANTASY_ADMIN = "FANTASY_ADMIN"; // ADMIN

// 当前用户默认进入页面
export const OVERSEAS_DIRECT_STOTE =
  "/overseasShellSpeedShop/overseasShellSpeedShop-OverseasDirectStore";
export const OVERSEAS_SHOP_NUMBER =
  "/overseasShellSpeedShop/overseasShellSpeedShop-overseasShopNumber";
export const OVERSEAS_SHELLSPEED_SHOP =
  "/overseasShellSpeedShop/overseasShellSpeedShop-OverseasDirectStoreFranchise";
export const OVERSEAS_LOGIN = "/user/login";

// 请求头
export const RESPONSE_HEADER_CONTENT_DISPOSITION = "content-disposition";
export const RESPONSE_HEADER_CONTENT_TYPE = "content-type";

export const REQUEST_HEADER_PAGINATION_INDEX = "x-pagination-index";
export const REQUEST_HEADER_PAGINATION_SIZE = "x-pagination-size";
export const REQUEST_HEADER_USER_LANG = "x-user-lang";
export const REQUEST_HEADER_CONTENT_TYPE = "Content-Type";
export const REQUEST_HEADER_AUTHORIZATION = "Authorization";
export const REQUEST_HEADER_GOOGLECODE = "GOOGLECODE";

// MIME TYPE
export const MIMETYPE_EXCEL =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const MIMETYPE_CSV = "text/csv";
export const MIMETYPE_JSON = "application/json";
export const MIMETYPE_FORMDATA = "multipart/form-data";
export const HEADERS_ACCEPT_DOWNLOAD = [MIMETYPE_EXCEL, MIMETYPE_CSV];

// 所有权限常量
export const MASTER__JURISDICTIONADMIN__AUTH_SEARCH =
  "MASTER__JURISDICTIONADMIN__AUTH_SEARCH"; //? ユーザ一覧画面 用户一览页面 检索

export const MASTER__JURISDICTIONADMIN__AUTH_EDIT =
  "MASTER__JURISDICTIONADMIN__AUTH_EDIT"; //? ユーザ一覧画面 用户一覈页面 编辑

export const MASTER__JURISDICTIONADMIN__AUTH_DELETE =
  "MASTER__JURISDICTIONADMIN__AUTH_DELETE"; //? ユーザ一覧画面 用户一覧页面 删除

export const MASTER__JURISDICTIONADMIN__AUTH_ADD =
  "MASTER__JURISDICTIONADMIN__AUTH_ADD"; //? ユーザ一覧画面 用户一覧页面 新增

export const MASTER__JURISDICTIONADMIN__AUTH_SEND =
  "MASTER__JURISDICTIONADMIN__AUTH_SEND"; //? ユーザ一覧画面 用户一覧页面 送信

export const MASTER__USERADMIN__AUTH_SEARCH = "MASTER__USERADMIN__AUTH_SEARCH"; //? ロール一覧画面 角色管理页面 检索

export const MASTER__USERADMIN__AUTH_EDIT = "MASTER__USERADMIN__AUTH_EDIT"; //? ロール一覧画面 角色管理页面 编辑

export const MASTER__USERADMIN__AUTH_DELETE = "MASTER__USERADMIN__AUTH_DELETE"; //? ロール一覧画面 角色管理页面 删除

export const MASTER__USERADMIN__AUTH_ADD = "MASTER__USERADMIN__AUTH_ADD"; //? ロール一覧画面 角色管理页面 新增

export const MASTER__DICTSADMIN__AUTH_SEARCH =
  "MASTER__DICTSADMIN__AUTH_SEARCH"; //? コードマスタ 字典管理页面 检索

export const MASTER__DICTSADMIN__AUTH_EDIT = "MASTER__DICTSADMIN__AUTH_EDIT"; //? コードマスタ 字典管理页面 编辑

export const MASTER__DICTSADMIN__AUTH_DELETE =
  "MASTER__DICTSADMIN__AUTH_DELETE"; //? コードマスタ 字典管理页面 删除

export const MASTER__DICTSADMIN__AUTH_ADD = "MASTER__DICTSADMIN__AUTH_ADD"; //? コードマスタ 字典管理页面 新增

export const MASTER__NOTICE__AUTH_SEARCH = "MASTER__NOTICE__AUTH_SEARCH"; //? 周知日 页面 检索

export const MASTER__NOTICE__AUTH_EDIT = "MASTER__NOTICE__AUTH_EDIT"; //? 周知日 页面 编辑

export const MASTER__NOTICE__AUTH_SEND = "MASTER__NOTICE__AUTH_SEND"; //? 周知日 页面 送信

export const SHOP__SHOPINFO__AUTH_SEARCH = "SHOP__SHOPINFO__AUTH_SEARCH"; //? "各国店舗一览 (直营)" 页面 检索

export const SHOP__SHOPINFO__AUTH_EDIT = "SHOP__SHOPINFO__AUTH_EDIT"; //? "各国店舗一览 (直营)" 页面 编辑

export const SHOP__SHOPINFO__AUTH_DOWNLOAD = "SHOP__SHOPINFO__AUTH_DOWNLOAD"; //? "各国店舗一览 (直营)" 页面 导出

export const SHOP__SHOPINFO__AUTH_ADD = "SHOP__SHOPINFO__AUTH_ADD"; //? "各国店舗一览 (直营)" 页面 新增

export const SHOP__SHOPINFO__AUTH_SEND = "SHOP__SHOPINFO__AUTH_SEND"; //? "各国店舗一览 (直营)" 页面 送信【完了】

export const SHOP__SHOPINFO__AUTH_DELETE = "SHOP__SHOPINFO__AUTH_DELETE"; //? "各国店舗一览 (直营)" 页面 删除

export const SHOP__SALES__AUTH_SEARCH = "SHOP__SALES__AUTH_SEARCH"; //? "店铺数集计" 检索

export const SHOP__SALES__AUTH_EDIT = "SHOP__SALES__AUTH_EDIT"; //? "店铺数集计" 编辑

export const MASTER__FIRMADMIN__AUTH_SEARCH = "MASTER__FIRMADMIN__AUTH_SEARCH"; //? 屋号管理检索权限

export const MASTER__FIRMADMIN__AUTH_EDIT = "MASTER__FIRMADMIN__AUTH_EDIT"; //? 屋号管理编辑权限

export const MASTER__DEPLOYERADMIN__AUTH_EDIT =
  "MASTER__DEPLOYERADMIN__AUTH_EDIT"; //? ディベロッパーマスタ 编辑

export const MASTER__DEPLOYERADMIN__AUTH_DELETE =
  "MASTER__DEPLOYERADMIN__AUTH_DELETE"; //? ディベロッパーマスタ 删除

export const MASTER__DEPLOYERADMIN__AUTH_ADD =
  "MASTER__DEPLOYERADMIN__AUTH_ADD"; //? ディベロッパーマスタ 新增

export const SHOP__SHOPINFOFC__AUTH_SEARCH = "SHOP__SHOPINFOFC__AUTH_SEARCH"; //? "各国店舗 [ 特许经营 ]" 页面 检索

export const SHOP__SHOPINFOFC__AUTH_EDIT = "SHOP__SHOPINFOFC__AUTH_EDIT"; //? "各国店舗 [ 特许经营 ]" 页面 编辑

export const SHOP__SHOPINFOFC__AUTH_DOWNLOAD =
  "SHOP__SHOPINFOFC__AUTH_DOWNLOAD"; //? "各国店舗 [ 特许经营 ]" 页面 导出

export const SHOP__SHOPINFOFC__AUTH_ADD = "SHOP__SHOPINFOFC__AUTH_ADD"; //? "各国店舗 [ 特许经营 ]" 页面 新增

export const SHOP__SHOPINFOFC__AUTH_DELETE = "SHOP__SHOPINFOFC__AUTH_DELETE"; //? "各国店舗 [ 特许经营 ]" 页面 删除

export const SHOP__SHOPINFOFC__AUTH_SEND = "SHOP__SHOPINFOFC__AUTH_SEND"; //? "各国店舗 [ 特许经营 ]" 页面 送信【完了】

export const EARNINGSSHOP_EARNINGSSALES_AUTH_SEARCH =
  "EARNINGSSHOP_EARNINGSSALES_AUTH_SEARCH"; //? 海外売速 - 売速集計 检索

export const EARNINGSSHOP_EARNINGSINFOS_AUTH_SEARCH =
  "EARNINGSSHOP_EARNINGSINFOS_AUTH_SEARCH"; //? 海外売速 - 各国売速一覧 检索

export const EARNINGSSHOP__EARNINGSUPLOAD__AUTH_UPLOAD =
  "EARNINGSSHOP__EARNINGSUPLOAD__AUTH_UPLOAD"; //? 海外売速 - 売速ファイルアップロード 上传

export const MASTER__AREAADMIN__AUTH_SEARCH = "MASTER__AREAADMIN__AUTH_SEARCH"; //? 地区管理 检索

export const MASTER__AREAADMIN__AUTH_EDIT = "MASTER__AREAADMIN__AUTH_EDIT"; //? 地区管理 编辑

export const MASTER__AREAADMIN__AUTH_DELETE = "MASTER__AREAADMIN__AUTH_DELETE"; //? 地区管理 删除

export const MASTER__AREAADMIN__AUTH_ADD = "MASTER__AREAADMIN__AUTH_ADD"; //? 地区管理 新增

export const MASTER__RATESETTING__AUTH_ADD = "MASTER__RATESETTING__AUTH_ADD"; //? 円換算レート設定  新增

export const MASTER__RATESETTING__AUTH_SEARCH =
  "MASTER__RATESETTING__AUTH_SEARCH"; //? 円換算レート設定  检索

export const MASTER__RATESETTING__AUTH_DELETE =
  "MASTER__RATESETTING__AUTH_DELETE"; //? 円換算レート設定  删除

export const MASTER__RATESETTING__AUTH_EDIT = "MASTER__RATESETTING__AUTH_EDIT"; //? 円換算レート設定  编辑
